<template>
  <div :class="['table', { active: item?.id === getDeletedSpecsTypes[0]?.id && isDelete }]">
    <div class="table__header">
      <div class="table__header-left">
        <h2 v-if="title && !edit" @click="deleteTable">{{ title }}<p v-if="item?.valuesClass === 'Region'">({{ $t('region') }})</p></h2>
        <UiInput v-if="!title || edit" v-model="titleValue" />
        <img @click="edit = true" v-if="!hideEditIcon && title && !edit" src="@/assets/svg/admin/edit-default.svg" alt="" />
        <img @click="edit = !edit" v-if="(edit || !title) && (!titleValue || title === titleValue)" src="@/assets/images/check-disable.png" alt="">
        <img @click="updateTitle" v-if="titleValue && titleValue !== title" src="@/assets/images/check-active.png" alt="">
      </div>
      <div class="table__header-right" v-if="addColumns" @click="newColumn=true"><img src="@/assets/svg/icon-plus-red.svg" alt=""/>{{ $t('new-column') }}</div>
    </div>
    <div class="table__description" :style="{paddingRight: isBase || isAlgorithm ? '160px' : isFinal ? '100px' : columnsCount > 1 || (newColumn && columnsCount === 1) ? '14%' : !sort && showId ? '324px' : '260px'}">
      <p class="table__description-number" v-if="showId">N<span>o</span></p>
      <p class="table__description-sort" v-if="sort">{{ $t('sort') }}</p>
      <div
          v-for="(item, key) in descriptionList"
          :key="key + columns.length"
          class="table__description-item"
          :style="{maxWidth: item.width + 'px', paddingLeft: item.paddingLeft + 'px', textAlign: item.textAlign}"
      >{{ item.title }}</div>
      <table-column-title
          v-for="(el, key) in columns"
          :key="key"
          :el="el"
          :item="item"
          @editColumns="editColumns($event)"
          @deleteColumn="deleteColumn($event)"
      />
      <div class="table__description-inp" v-if="newColumn">
        <ui-input placeholder="..." v-model="newColumnName"/>
        <img
            v-if="!newColumnName"
            src="@/assets/images/check-disable.png"
            alt=""
            @click="newColumn = false"
        >
        <img
            v-if="newColumnName"
            src="@/assets/images/check-active.png"
            alt=""
            @click="addColumn"
        >
      </div>
    </div>
    <div class="table__body">
      <AdminTableRow
          :sort="sort"
          :attention="attention"
          :columnNumInfo="item"
          @updateDescription="$emit('updateDescription', $event)"
          @deleteRowField="$emit('deleteRowField', $event)"
          @changeRowField="$emit('changeRowField', $event)"
          @showEditColumnNumber="$emit('showEditColumnNumber', $event)"
          @editColumnNumber="$emit('editColumnNumber', $event)"
          @addNewNumber="$emit('addNewNumber', $event)"
          @changeType="changeType($event)"
          v-for="(el, key) in getData"
          v-show="el.specGroup === specGroup"
          :key="key"
          :data="el"
          :showSelect="showSelect"
          :hideDeleteIcon="hideDeleteIcon"
          :showNewColumn="newColumn"
          :newColumn="columns"
          :columnsCount="columnsCount"
          :isRatio="isRatio"
          :isDistrictRegion="isDistrictRegion"
          :isBase="isBase"
          :isFinal="isFinal"
          :isAlgorithm="isAlgorithm"
          :shortName="shortName"
          :periods="periods"
          :isCheckbox="isCheckbox"
          :isGlobeIcon="isGlobeIcon"
          :isCode="isCode"
          :showId="showId"
          :internationalName="internationalName"
      />
      <TableNewRow
          v-for="(item, key) in newRows"
          :sort="sort"
          :attention="attention"
          @addRowField="$emit('addRowField', $event)"
          @deleteNewRowField="deleteNewRowField()"
          :key="item[key]"
          :data="item"
          :edit="item.isEdit"
          :showSelect="showSelect"
          :hideDeleteIcon="hideDeleteIcon"
          :showNewColumn="newColumn"
          :newColumn="columns"
          :columnsCount="columnsCount"
          :isRatio="isRatio"
          :isDistrictRegion="isDistrictRegion"
          :isBase="isBase"
          :isFinal="isFinal"
          :isAlgorithm="isAlgorithm"
          :shortName="shortName"
          :periods="periods"
          :isCheckbox="isCheckbox"
          :isGlobeIcon="isGlobeIcon"
          :isCode="isCode"
          :showId="showId"
          :internationalName="internationalName"
      />
    </div>
    <div class="table__footer" v-if="showAction">
      <ui-button :disabled="newRows.length === 1" color="outline" class="table__footer-action" @click="addItem" v-if="item?.valuesClass !== 'Region'">
        <img src="@/assets/svg/icon-plus-red.svg" alt="">
        <p>{{ actionText }}</p>
      </ui-button>
      <ui-button v-if="item?.valuesClass === 'Region'" :disabled="newRows.length === 1" color="outline" class="table__footer-action" @click="addItem">
        <img src="@/assets/svg/icon-plus-red.svg" alt="">
        <p>{{ $t('new-region') }}</p>
      </ui-button>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from "vuex";
import TableColumnTitle from "@/components/common/admin/TableColumnTitle.vue";

export default {
  name: "UiTable",
  components: {
    TableColumnTitle,
    UiButton: () => import('@/components/ui/UiButton'),
    AdminTableRow: () => import("@/components/common/admin/TableRow"),
    TableNewRow: () => import("@/components/common/admin/TableNewRow"),
    UiInput: () => import('@/components/ui/UiInput')
  },
  props: {
    attention: {
      type: Boolean,
      default: false
    },
    sort: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    actionText: {
      type: String,
      default: ''
    },
    showAction: {
      type: Boolean,
      default: true
    },
    showSelect: {
      type: Boolean,
      default: false
    },
    descriptionList: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    },
    hideDeleteIcon: {
      type: Boolean,
      default: false
    },
    hideEditIcon: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {}
    },
    newTable: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Array,
      default: () => []
    },
    addColumns: {
      type: Boolean,
      default: false
    },
    columnsCount: {
      type: Number,
      default: 0
    },
    isDelete: {
      type: Boolean,
      default: false
    },
    typeId: {
      type: Number,
      default: 0
    },
    specGroup: {
      type: undefined,
      default: undefined
    },
    isRatio: {
      type: Boolean,
      default: false
    },
    isDistrictRegion: {
      type: Boolean,
      default: false
    },
    isBase: {
      type: Boolean,
      default: false
    },
    isFinal: {
      type: Boolean,
      default: false
    },
    isAlgorithm: {
      type: Boolean,
      default: false
    },
    shortName: {
      type: Boolean,
      default: false
    },
    periods: {
      type: Boolean,
      default: false
    },
    isCheckbox: {
      type: Boolean,
      default: false
    },
    isGlobeIcon: {
      type: Boolean,
      default: false
    },
    isCode: {
      type: Boolean,
      default: false
    },
    showId: {
      type: Boolean,
      default: true
    },
    internationalName: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      edit: false,
      newVal: '',
      fieldIndex: null,
      newRows: [],
      res: [],
      titleValue: "",
      newColumn: false,
      newColumnName: '',
      tableOffsetWidth: 0
    }
  },
  watch: {
    edit(val) {
      if(val) {
        this.titleValue = this.title
      }
    }
  },
  computed: {
    ...mapGetters(['getVariablesValues', 'getDeletedSpecsTypes']),

    getData() {
      return this.newTable ? this.res : this.data
    }
  },
  created() {
    if(this.newTable) {
      let payload = {
        id: this.item.id,
        name: this.item.name
      }
      this.variableValues(payload).then((res) => {
        this.res = res.data
      })
    }

    addEventListener('resize', () => this.tableInfo());

    this.tableInfo();
  },

  methods: {
    ...mapActions(['variableValues']),
    ...mapMutations(['deleteSpecsTypes']),
    editField() {
      this.edit = !this.edit
    },
    editColumns(item) {
      this.$emit('editColumn', item)
    },
    showEditColumn(item) {
      this.$store.commit('showEditColumn', item)
    },
    deleteColumn(item) {
      this.$emit('deleteColumn', item)
    },
    addItem(data) {
      this.newRows.push({ name: '', isEdit: true, typeId: this.data[0]?.typeId || this.typeId, id: this.item?.id || data?.id, valuesClass: this.item?.valuesClass})
    },
    deleteNewRowField() {
      this.newRows.pop()
    },
    changeType(data) {
      this.$emit('changeType', data)
    },
    updateTitle() {
      if(!this.title) {
        this.$emit('addTitle', this.titleValue)
      }
      else {
        this.$emit('updateTitle', {value: this.titleValue, id: this.item.id})
      }
      this.edit = !this.edit;
    },
    addColumn() {
      this.$emit('addColumn', {name: this.newColumnName, specTypeId: this.item?.id})
    },
    deleteTable() {
      if (this.isDelete) {
        this.deleteSpecsTypes(this.item)
      }
    },
    tableInfo() {
      const body = document.querySelector('body');
      if (body) {
        this.tableOffsetWidth = body.offsetWidth - 100 + 'px';
      }
    }
  },
}
</script>

<style lang="scss" scoped>
 .table {
   background: #FFFFFF;
   box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
   border-radius: 20px;
   animation: fade-out 1s ease-out;
   border: 1px solid transparent;
   transition: 0.3s;
   min-width: 800px;

   &.active {
     border: 1px solid red;
   }

   @keyframes fade-out {
     0% {
       opacity: 0;
     }
     100% {
       opacity: 1;
     }
   }

   &__header {
     display: flex;
     align-items: center;
     justify-content: space-between;
     padding: 24px 30px 24px 20px;

     &-left {
       display: flex;
       align-items: center;
       gap: 14px;

       ::v-deep .ui-input {
         max-width: 300px;
       }

       h2 {
         font-size: 16px;
         line-height: 19px;
         color: #1B1A1F;
         cursor: pointer;
         display: flex;
         align-items: center;
         gap: 5px;
       }

       img {
         width: 16px;
         height: 16px;
         cursor: pointer;
       }
     }

     &-right {
       display: flex;
       align-items: center;
       cursor: pointer;
       gap: 10px;
       color: #CE2121;
       font-size: 14px;
       font-weight: 400;
     }
   }

   &__description {
     display: flex;
     align-items: center;
     padding: 10px 20px 10px 0;
     background: #E21F1F;

     &-item {
       width: 100%;
       font-weight: 400;
       font-size: 14px;
       line-height: 17px;
       color: #FFFFFF;
       padding-left: 20px;
     }

     &-columns {
       display: flex;
       align-items: center;
       gap: 5px;
       width: 100%;
       font-weight: 400;
       font-size: 14px;
       line-height: 17px;
       color: #FFFFFF;
       padding-left: 20px;
       max-width: 180px;
       text-align: center;

       img {
         width: 16px;
         height: 16px;
         cursor: pointer;
       }

       p {
         display: flex;
         align-items: center;
         justify-content: center;
         gap: 5px;
         padding-left: 40px;
         cursor: pointer;
       }

       &-inp {
         display: flex;
         align-items: center;
         gap: 5px;

         img {
           width: 16px;
           height: 16px;
           cursor: pointer;
         }

         ::v-deep .ui-input {
           input {
             border: none;
             padding: 5px 3px;
             height: auto;
             color: #FFFFFF;
           }
         }
       }
     }

     &-attention {
       display: flex;
       justify-content: center;
       color: #FFFFFF;
       font-size: 14px;
       max-width: 128px;
       width: 100%;
     }

     &-sort {
       display: flex;
       justify-content: center;
       color: #FFFFFF;
       font-size: 14px;
       max-width: 63px;
       width: 100%;
     }

     &-number {
       display: flex;
       justify-content: center;
       gap: 2px;
       position: relative;
       color: #FFFFFF;
       font-size: 14px;
       max-width: 62px;
       width: 100%;

       span {
         position: absolute;
         top: 2px;
         right: 20px;
         font-size: 8px;
         color: #FFFFFF;
       }
     }
   }

   &__body {
    &-row {
      display: flex;

      .table-item {
        font-size: 14px;
        line-height: 120.2%;
        color: #1B1A1F;
        padding: 10px 20px;
        border: 1px solid #F5F5F5;
        max-width: 440px;
        width: 100%;
        min-height: 40px;

        &.edit {
          padding: 0;
        }

        ::v-deep input {
          border: none;
        }
      }
      .table-select {
        max-width: 200px;
        width: 100%;
        border: 1px solid #F5F5F5;

        ::v-deep .ui-select__inner {
          border: none;
        }
        ::v-deep .ui-select {
          z-index: auto;
        }
        ::v-deep .ui-select__options {
          top: 40px;
        }
      }
      .table-item-center {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #F5F5F5;
        max-width: 280px;
        width: 100%;

        img {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
      }
    }
   }

   &__footer {
     display: flex;
     align-items: center;

     &-action {
       width: max-content !important;
       padding: 20px !important;

       &:hover {
         text-decoration: none !important;
       }

       p {
         font-weight: 400;
         font-size: 14px;
         line-height: 17px;
         color: #CE2121;
       }
     }
   }
 }
 .table__description-inp {
   display: flex;
   align-items: center;justify-content: space-between;
   max-width: 180px;
   width: 100%;
   margin-left: 20px;

   img {
     width: 16px;
     height: 16px;
     cursor: pointer;
   }
   ::v-deep input {
     color: #FFFFFF !important;
     border: none !important;
     height: 7px !important;

     &::placeholder {
       color: #FFFFFF;
     }
   }
 }
</style>