<template>
  <div class="table__description-columns" v-show="item ? el.specTypeId === item?.id : true">
    <p @click="showEditColumn" v-if="!isEditColumn">{{ el.name }}</p>
    <img v-if="!isEditColumn" src="@/assets/svg/icon-delete-admin.svg" alt="" @click="showAlert = !showAlert"/>
    <div v-else class="table__description-columns-inp">
      <ui-input v-model="editVal" />
      <img
          v-if="isEditColumn && editVal && editVal !== el.name"
          @click="editColumns(el)"
          src="@/assets/images/check-active.png"
          alt=""
      >
      <img
          v-else
          @click="isEditColumn = !isEditColumn"
          src="@/assets/images/check-disable.png"
          alt=""
      >
    </div>
    <UiAlert v-model="showAlert">
      <div class="ui-alert__title" :style="{color: 'black'}">{{ $t('do-you-really-want-to-delete') }}</div>
      <div class="ui-alert__action">
        <ui-button @click="deleteColumn(el)" color="error">{{ $t('delete') }}</ui-button>
        <ui-button @click="showAlert = false" color="white">{{ $t('cancel-2') }}</ui-button>
      </div>
    </UiAlert>
  </div>
</template>

<script>
import UiInput from "@/components/ui/UiInput.vue";
import UiAlert from "@/components/ui/UiAlert.vue";
import UiButton from "@/components/ui/UiButton.vue";

export default {
  name: "TableColumnTitle",
  components: {UiButton, UiAlert, UiInput},
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    el: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      isEditColumn: false,
      editVal: '',
      showAlert: false
    }
  },

  methods: {
    deleteColumn(item) {
      this.$emit('deleteColumn', item)
    },
    editColumns(item) {
      this.el.editForm = this.editVal
      this.$emit('editColumns', item)
    },
    showEditColumn() {
      this.isEditColumn = !this.isEditColumn
    }
  },

  mounted () {
    this.editVal = this.el.name
  }
}
</script>

<style lang="scss" scoped>
.table__description-columns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  padding-left: 20px;
  max-width: 180px;
  text-align: center;

  img {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
  }

  &-inp {
    display: flex;
    align-items: center;
    gap: 5px;

    img {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }

    ::v-deep .ui-input {
      input {
        border: none;
        padding: 5px 30px 5px 3px;
        height: auto;
        color: #FFFFFF;
      }
    }
  }
}
</style>